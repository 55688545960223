import React, { useState } from 'react';
import FrontendConditions from './frontendconditions';
import OrderNotStarted from './orderNotStarted';
import OrderPlacementStarted from './orderPlacementStarted';

const Main = () => {
  const [manageRoute, setManageRoute] = useState('FrontendConditions');
  const onOpenForm = (type) => {
    setManageRoute(type);
  };

  return (
    <>
      {manageRoute === 'FrontendConditions' ? (
        <FrontendConditions onOpenForm={onOpenForm} />
      ) : manageRoute === 'OrderNotStarted' ? (
        <OrderNotStarted onOpenForm={onOpenForm} />
      ) : manageRoute === 'OrderPlacementStarted' ? (
        <OrderPlacementStarted onOpenForm={onOpenForm} />
      ) : (
        ''
      )}
      {/* <FrontendConditions onOpenForm={onOpenForm} />
      <OrderNotStarted onOpenForm={onOpenForm} />
      <OrderPlacementStarted onOpenForm={onOpenForm} /> */}
    </>
  );
};

export default Main;
