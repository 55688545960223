import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'src/App';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import { socket } from 'src/helpers/context/socket';

const CurrentPrice = ({ viewData }) => {
  const intlContext = useContext(IntlContext);
  const [price, setPrice] = useState(0);
  const localesData = intlContext?.messages;

  useEffect(() => {
    const amt = fetchFromStorage(identifiers.amount);
    if (amt && amt !== undefined) {
      setPrice(amt);
    } else {
      // setPrice(viewData?.start_price);
      socket.on('priceUpdate', (data) => {
        setPrice(data?.amount);
      });
    }
    // <Startinterval product={viewData} />;
    // eslint-disable-next-line
  }, [price]);

  useEffect(() => {
    socket.on('priceUpdate', (data) => {
      setPrice(data?.amount);
    });
  }, []);
  return (
    <Box className='current-person-sec'>
      <Typography className='current-title-text pb-46'>
        {localesData?.banner_sec?.current_price}
      </Typography>
      <Typography className='money-text'>
        {/* {localesData?.banner_sec?.money_50000000} */}
        <span>{localesData?.banner_sec?.money_tag}</span>
        {Number(price === 0 ? viewData?.current_price : price).toLocaleString(
          'de-DE',
          { minimumFractionDigits: 0, maximumFractionDigits: 0 }
        )}
      </Typography>
    </Box>
  );
};

export default CurrentPrice;
