import {
  Box,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import Banner from '../../UI/banner';
import { IntlContext } from 'src/App';
import { ReactComponent as CheckedIcon } from 'src/assets/images/checkedIcon.svg';
import { ReactComponent as UnCheckIcon } from 'src/assets/images/unCheckedIcon.svg';
import { CustomTextField } from '../../UI/textfield';
import ProjectName from 'src/view/component/UI/projectName.js';
import OnlineProcurement from '../../UI/onlineprocurement';
import { useParams, useNavigate } from 'react-router-dom';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { socket } from 'src/helpers/context/socket';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import './frontendconditions.scss';

const FrontendConditions = ({ onOpenForm }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const { id } = useParams();

  const [viewData, setViewData] = useState();

  // GET PRODUCT
  const getproduct = async () => {
    try {
      // setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.getProduct + `/${id}`
      );

      if (status === 200) {
        setViewData(data?.data);
        // setLoader(false);
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        navigate('/page-not-found');
      }
      // setLoader(false);
    }
  };
  useEffect(() => {
    getproduct();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    socket.on('JoinSocket', (data) => {
      // console.log('JoinSocket', data);
    });
    socket.on('ProductStarted', (data) => {
      getproduct();
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Box className='accept-frontend-conditions'>
      <Banner title={localesData?.register?.Front} />
      <Box>
        <Box className='container'>
          <ProjectName viewData={viewData} />
          <OnlineProcurement viewData={viewData} />

          <>
            <Box>
              <Typography className='desc-para-text pb-70'>
                {localesData?.banner_sec?.ordering_condition}
              </Typography>
              <Formik
                // innerRef={formRef}
                initialValues={{
                  company_name: '',
                  firstname: '',
                  lastname: '',
                  email: '',
                  terms_and_conditions: true,
                  work_contract: true
                }}
                validationSchema={Yup.object().shape({
                  company_name: Yup.string().required(
                    localesData?.validation?.company_name
                  ),
                  firstname: Yup.string().required(
                    localesData?.validation?.first_name
                  ),
                  lastname: Yup.string().required(
                    localesData?.validation?.last_name
                  ),
                  email: Yup.string()
                    .email(localesData?.validation?.valid_email)
                    .max(255)
                    .required(localesData?.validation?.email),
                  terms_and_conditions: Yup.boolean().oneOf(
                    [true],
                    'Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren, um fortzufahren'
                  ),
                  work_contract: Yup.boolean().oneOf(
                    [true],
                    'Sie müssen die Allgemeinen Obermair Immobilien GmbH Werkvertrag , um fortzufahren'
                  ),
                })}
                onSubmit={async (requestData) => {
                  let sendData = {
                    product_id: id,
                    email: requestData?.email,
                    name: requestData?.company_name,
                    first_name: requestData?.firstname,
                    last_name: requestData?.lastname,
                  };
                  if (
                    requestData.company_name !== '' &&
                    requestData.firstname !== '' &&
                    requestData.lastname !== '' &&
                    requestData.email !== '' &&
                    requestData.terms_and_conditions !== false
                  ) {
                    try {
                      // setLoader(true);
                      const { status, data } = await axiosInstance.post(
                        URLS.participate,
                        sendData,
                        {
                          headers: {
                            'Accept-Language': fetchFromStorage(
                              identifiers.acceptlanguage
                            ),
                          },
                        }
                      );
                      if (status === 200) {
                        if (data?.status) {
                          setApiMessage('success', data?.message);
                          onOpenForm('OrderNotStarted');
                          socket.emit('JoinSocket', {
                            user_id: data?.data?.id,
                            product_id: data?.data?.product_id,
                          });
                          saveToStorage(
                            identifiers.participateData,
                            data?.data
                          );
                        } else {
                          setApiMessage('error', data?.message);
                        }
                        // setLoader(false);
                      }
                    } catch (error) {
                      setApiMessage('error', error?.response?.data?.message);
                      // setLoader(false);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <Box className='company-name-field'>
                      <Typography className='regular-font'>
                        {localesData?.banner_sec?.name_company}
                        <span>*</span>
                      </Typography>
                      <CustomTextField
                        error={Boolean(
                          touched.company_name && errors.company_name
                        )}
                        fullWidth
                        helperText={touched.company_name && errors.company_name}
                        variant='outlined'
                        // className="custom-textfield reg-textfield"
                        type='text'
                        name='company_name'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.company_name}
                      />
                    </Box>
                    <Box className='contact-person-field'>
                      <Typography className='regular-font'>
                        {localesData?.banner_sec?.contact_person}
                        <span>*</span>
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <CustomTextField
                            placeholder={localesData?.banner_sec?.first_name}
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            fullWidth
                            helperText={touched.firstname && errors.firstname}
                            variant='outlined'
                            // className="custom-textfield reg-textfield"
                            name='firstname'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <CustomTextField
                            placeholder={localesData?.banner_sec?.last_name}
                            error={Boolean(touched.lastname && errors.lastname)}
                            fullWidth
                            helperText={touched.lastname && errors.lastname}
                            variant='outlined'
                            // className="custom-textfield reg-textfield"
                            name='lastname'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className='email-address-field'>
                      <Typography className='regular-font'>
                        {localesData?.banner_sec?.email_address}
                        <span>*</span>
                      </Typography>
                      <CustomTextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        variant='outlined'
                        // className="custom-textfield reg-textfield"
                        name='email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                    </Box>
                    <Typography className='required_text'>
                      {localesData?.banner_sec?.reduired_fields}
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<UnCheckIcon />}
                            checkedIcon={<CheckedIcon />}
                            checked={values.terms_and_conditions}
                            name='terms_and_conditions'
                          />
                        }
                        className='required-check-box'
                        label={
                          <Typography
                            className='required_text pl-8'
                            dangerouslySetInnerHTML={{
                              __html:
                                localesData?.banner_sec
                                  ?.agree_to_terms_and_conditions,
                            }}
                          ></Typography>
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    {Boolean(
                      touched.terms_and_conditions &&
                        errors.terms_and_conditions
                    ) && (
                      <span
                        errors
                        style={{
                          marginBottom: '18px',
                          color: '#f44336',
                          fontSize: '0.75rem',
                          fontWeigh: '400',
                        }}
                      >
                        {errors.terms_and_conditions}
                      </span>
                    )}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<UnCheckIcon />}
                            checkedIcon={<CheckedIcon />}
                            checked={values.work_contract}
                            name='work_contract'
                          />
                        }
                        className='required-check-box'
                        label={
                          <Typography
                            className='required_text pl-8'
                            dangerouslySetInnerHTML={{
                              __html:
                                localesData?.banner_sec
                                  ?.agree_to_work_contract,
                            }}
                          ></Typography>
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    {Boolean(
                      touched.work_contract &&
                        errors.work_contract
                    ) && (
                      <span
                        errors
                        style={{
                          marginBottom: '18px',
                          color: '#f44336',
                          fontSize: '0.75rem',
                          fontWeigh: '400',
                        }}
                      >
                        {errors.work_contract}
                      </span>
                    )}
                    <Box className='continue-sec'>
                      <Button
                        type='submit'
                        variant='contained'
                        className='continue-btn'
                      >
                        {localesData?.banner_sec?.continue}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default FrontendConditions;
