// import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import { publicRoute, privateRoute } from 'src/routes';
import messagesEn from 'src/helpers/locales/en.json';
import messagesDe from 'src/helpers/locales/de.json';
// import messagesTh from "src/helpers/locales/th.json";
import 'src/assets/scss/global.scss';
import { SocketContext, socket } from 'src/helpers/context/socket';
import { useSelector } from 'react-redux';
import { fetchFromStorage, saveToStorage } from './helpers/context';
import { identifiers } from './helpers/constants/identifier';
const Context = createContext();
const ViewPortContext = createContext({});

const App = () => {
  const navigate = useNavigate();
  const menuMessages = {
    de: { ...messagesDe },
    en: { ...messagesEn },

    // th: { ...messagesTh },
    // fr: { ...messagesFr },
    // pt: { ...messagesPt },
  };

  const [locale, setLocale] = useState('de');
  const [messages, setMessages] = useState(menuMessages['de']);
  // const reduxAuth = useSelector((state) => state?.reduxData?.authData);
  const localAuth = fetchFromStorage(identifiers.authData);
  const auth = localAuth;

  const viewRoutes = auth && auth ? privateRoute : publicRoute;

  const routing = useRoutes(viewRoutes);

  // STATE BIND WINDOW SIZE
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const breakPoint = 1020;
  const messagesBreakPoint = 960;

  const switchLanguage = (lang) => {
    saveToStorage(identifiers.acceptlanguage, lang);
    setLocale(lang);
    setMessages(menuMessages[lang]);
  };
  useEffect(() => {
    var defaultLang =
      fetchFromStorage(identifiers.acceptlanguage) !== null
        ? fetchFromStorage(identifiers.acceptlanguage)
        : 'de';
    setLocale(defaultLang);
    setMessages(menuMessages[defaultLang]);
    saveToStorage(identifiers.acceptlanguage, defaultLang);
    // eslint-disable-next-line
  }, [locale, fetchFromStorage(identifiers.acceptlanguage)]);

  // add event listener to monitor the window size change
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(document.documentElement.clientWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  useEffect(() => {
    socket.on('JoinSocket', (data) => {
      // console.log('JoinSocket', data);
    });
  });
  return (
    <ThemeProvider theme={theme}>
      {/* <Context.Provider value={{ locale, switchLanguage, messages, socket }}>  */}
      <Context.Provider value={{ locale, switchLanguage, messages }}>
        <ViewPortContext.Provider
          value={{ width, height, breakPoint, messagesBreakPoint }}
        >
          <SocketContext.Provider value={{ socket }}>
            <GlobalStyles />
            {routing}
          </SocketContext.Provider>
        </ViewPortContext.Provider>
      </Context.Provider>
    </ThemeProvider>
  );
};

export default App;
export { Context as IntlContext };
export { ViewPortContext };
