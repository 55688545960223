import React, { useState, useEffect, useContext } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { socket } from 'src/helpers/context/socket';
import { IntlContext } from 'src/App';
import 'react-circular-progressbar/dist/styles.css';
import './ui.scss';

const CircularTimer = ({ originalTime, selectTime }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  const totalDuration = selectTime;
  const savedTimeLeft = Number(fetchFromStorage('countdownTime'));
  const [timeLeft, setTimeLeft] = useState(
    selectTime ? totalDuration : savedTimeLeft
  );

  useEffect(() => {
    if (timeLeft > 0) {
      // const timer = setInterval(() => {
      //   setTimeLeft((prevTime) => prevTime - 1);
      //   saveToStorage('countdownTime', timeLeft - 1);
      // }, 1000);
      // return () => clearInterval(timer);
    }
  }, [timeLeft]);

  useEffect(() => {
    socket.on('productTime', (data) => {
      setTimeLeft(data?.timing);
      saveToStorage('countdownTime', data?.timing);
    });
    socket.on('priceUpdate', (data) => {
      setTimeLeft(data?.timing);
      saveToStorage('countdownTime', data?.timing);
    });
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const percentage = (timeLeft / (originalTime * 60)) * 100;

  return (
    <div className='circular-timer'>
      <CircularProgressbarWithChildren value={percentage}>
        <div
          style={{
            fontSize: 52,
            marginTop: -5,
            fontWeight: 700,
            color: '#64666B',
            display: 'flex',
            gap: '15px',
          }}
        >
          <p>{String(minutes).padStart(2, '0')}</p>
          <p>{String(seconds).padStart(2, '0')}</p>
        </div>
        <div
          style={{
            fontSize: 14,
            marginTop: 0,
            color: '#64666B',
            display: 'flex',
            gap: '15px',
          }}
        >
          <p>{localesData?.register?.minutes}</p>
          <p>{localesData?.register?.seconds}</p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularTimer;
